import React, { useCallback, useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Link } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import CancelButton from "../components/CancelButton";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";

interface BankFeedsRequestProps {
    api: OpenBankingPlatformAPI;
    currentDataHolderSelection?: DataHolder;
    basePath: string;
    isMultiBank?: boolean;
    generalSettingsConfig?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

interface BankFeedsRequestState {
    iframeUrl: string;
    callbackCompleted: boolean;
    isLoading: boolean;
}

function BankFeedsRequest(props: BankFeedsRequestProps) {
    const [state, setState] = useState<BankFeedsRequestState>({
        iframeUrl: "",
        callbackCompleted: false,
        isLoading: true
    })

    const dataHolderBrand = sessionStorage.getItem('dataHolderBrand') ?? JSON.stringify(props.currentDataHolderSelection);
    const storedDataHolderBrand: DataHolder = JSON.parse(dataHolderBrand);

    const navigate = useNavigate();

    const bankfeedsBaseUrl = process.env.REACT_APP_BANKFEEDS_API_ENDPOINT_URL;

    const cancelButtonRef = useRef<HTMLButtonElement>(null);  // Create a ref for the CancelButton

    //eslint-disable-next-line
    const requestBankfeedsApiUrl = useCallback(props.api.getNonCdrFlowInitialisationUrl, []);
    useEffect(() => {
        (async () => {
            if (storedDataHolderBrand.id) {
                try {
                    const bfResponse = await requestBankfeedsApiUrl(storedDataHolderBrand.id, props.isMultiBank);
                       // added ?scrollToTopCallback=yes to iframe url on Sep 9 2024 so only bf iframe hosted by OB will send scroll to top post msg
                    setState({
                        ...state,
                        iframeUrl: (bankfeedsBaseUrl + bfResponse.iframeUrl+'?scrollToTopCallback=yes'),
                        isLoading: false
                    })
                } catch (error) {
                    //console.log(error);
                    consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })();
    },
        //eslint-disable-next-line
        []
    )

    // Scroll to the top of the page once
    useEffect(() => {
        window.scrollTo(0, 0);
        window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
    }, []);

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const handleIframeTask = (e: any) => {

            if (e.data === 'cancel') {
                // Trigger the cancel functionality
                cancelButtonRef.current?.click();
                return;
            }

            if (e.origin !== process.env.REACT_APP_BANKFEEDS_API_ENDPOINT_URL) {
                return;
            }

            if (e.data.action && e.data.action === 'SCROLL_TO_TOP') {
                window.scrollTo(0, 0);
                window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
                return;
            }

            if (!state.callbackCompleted) {
                try {
                    const consentData = JSON.parse(e.data);
                    //saveBankFeedsConnection(storedDataHolderBrand.data_holder_id);
                    setState({
                        ...state,
                        callbackCompleted: true,
                    })
                    // For now just push to the success page,
                    // or the multibank connected institutions page (if enabled)
                    if (consentData.status === 'COMPLETE') {
                        navigate(props.basePath + "/consent/success", {
                            /*state: consentData.data.consent*/
                            state: {
                                consentData: consentData.data.data.standardised_data,
                                consentId: consentData.data.data.standardised_data.consents[0].consent_uuid
                            }
                        })
                        //navigate(props.basePath + "/consent/success");
                    } else if (consentData.status === 'ERROR') {
                        consentRedirectByErrorResponseStatus(navigate, {
                            status: 500,
                            data: {
                                error_code: 'bfapi',
                                error_message: 'An unexpected issue occurred when establishing a connection to the bank.',
                                uuid: 'N/A',
                                type: 'error',
                            }
                        });
                    }
                }
                catch (err) {}
            }
        };

        window.addEventListener('message', handleIframeTask);
    })

    const showIFrame = () => {
        const iframe = document.getElementById('bankfeeds-contents');
        if (iframe) {
            iframe.style.visibility = 'visible';
        }
    }

    return <div className={"page-wrapper bank-feeds-request"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                />
                {state.isLoading ? <LoadingSpinner position={"fixed"} overlay /> :
                    <PrimaryBlock>
                        <Box mb={2}>
                            <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                        </Box>

                        <h2>Connect to online banking via illion BankStatements</h2>
                        <iframe
                            id={"bankfeeds-contents"}
                            title={"BankFeeds"}
                            src={state.iframeUrl}
                            width={"100%"}
                            height={"auto"}
                            style={{ visibility: "hidden", border: "none", borderWidth: 0 }}
                            onLoad={() => {
                                showIFrame();
                                // Get the iframe element by ID and assert its type as HTMLIFrameElement
                                const iframe = document.getElementById('bankfeeds-contents') as HTMLIFrameElement;

                                // Check if the iframe is correctly referenced and send the message
                                if (iframe && iframe.contentWindow) {
                                    iframe.contentWindow.postMessage({ action: 'showCancelButton' }, '*');
                                }
                            }}>

                        </iframe>
                        <ButtonBlock>
                            <CancelButton
                                basePath={props.basePath}
                                api={props.api}
                                generalSettingsConfig={props.generalSettingsConfig}
                                ref={cancelButtonRef}  // Attach the ref to the CancelButton
                                style={{ display: 'none' }}  // This hides the button
                            >Cancel
                            </CancelButton>
                        </ButtonBlock>
                    </PrimaryBlock>
                }
            </main>
        </div>

        {!props.isTrustedAdviser && <Footer generalSettingsConfig={props.generalSettingsConfig} />}

    </div>
}
export default BankFeedsRequest;
